exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-comunidades-mdx": () => import("./../../../src/pages/acerca/comunidades.mdx" /* webpackChunkName: "component---src-pages-acerca-comunidades-mdx" */),
  "component---src-pages-acerca-curriculum-mdx": () => import("./../../../src/pages/acerca/curriculum.mdx" /* webpackChunkName: "component---src-pages-acerca-curriculum-mdx" */),
  "component---src-pages-acerca-index-mdx": () => import("./../../../src/pages/acerca/index.mdx" /* webpackChunkName: "component---src-pages-acerca-index-mdx" */),
  "component---src-pages-acerca-proyectos-personales-mdx": () => import("./../../../src/pages/acerca/proyectos-personales.mdx" /* webpackChunkName: "component---src-pages-acerca-proyectos-personales-mdx" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-slug-js": () => import("./../../../src/pages/blog/{mdx.slug}.js" /* webpackChunkName: "component---src-pages-blog-mdx-slug-js" */),
  "component---src-pages-blog-post-3-pasos-para-aprender-mdx": () => import("./../../../src/pages/blog/post/3-pasos-para-aprender.mdx" /* webpackChunkName: "component---src-pages-blog-post-3-pasos-para-aprender-mdx" */),
  "component---src-pages-blog-post-como-crear-una-app-mdx": () => import("./../../../src/pages/blog/post/como-crear-una-app.mdx" /* webpackChunkName: "component---src-pages-blog-post-como-crear-una-app-mdx" */),
  "component---src-pages-blog-post-creando-sitio-gatsby-mdx": () => import("./../../../src/pages/blog/post/creando-sitio-gatsby.mdx" /* webpackChunkName: "component---src-pages-blog-post-creando-sitio-gatsby-mdx" */),
  "component---src-pages-blog-post-porque-aprender-codigo-mdx": () => import("./../../../src/pages/blog/post/porque-aprender-codigo.mdx" /* webpackChunkName: "component---src-pages-blog-post-porque-aprender-codigo-mdx" */),
  "component---src-pages-contactame-js": () => import("./../../../src/pages/contactame.js" /* webpackChunkName: "component---src-pages-contactame-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-trabajos-js": () => import("./../../../src/pages/trabajos.js" /* webpackChunkName: "component---src-pages-trabajos-js" */)
}

